import "./App.css";
import React, { useRef, useState } from "react";
import {
  TextField,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Select,
  Box,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import { toastMessageError, toastMessageSuccess } from "./constants/toast";
import { SERVICE } from "./constants/api";
import SignatureCanvas from "react-signature-canvas";
import provincesData from "./constants/provincias.json";
import poblationData from "./constants/poblaciones.json";
import useConcentScript from "./utils/hooks/useConcent";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { set } from "zod";

const initialFormData = {
  name: "",
  surname1: "",
  surname2: "",
  nif: "",
  cnr: "",
  address: "",
  province: "",
  poblation: "",
  cp: "",
  email: "",
  estamento: "",
  sport: "",
  dni_image: "",
  representante: "",
  firma: "",
  certified_voto_club: false,
};

function App() {
  provincesData.sort((a, b) => {
    if (a.label < b.label) {
      return -1; // a va antes que b
    }
    if (a.label > b.label) {
      return 1; // b va antes que a
    }
    return 0; // a y b son iguales
  });
  poblationData.sort((a, b) => {
    if (a.label < b.label) {
      return -1; // a va antes que b
    }
    if (a.label > b.label) {
      return 1; // b va antes que a
    }
    return 0; // a y b son iguales
  });
  const sigPad = useRef(null);
  const { recogerConsentimiento } = useConcentScript();
  const [formData, setFormData] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState({
    name: "",
    surname1: "",
    nif: "",
    cnr: "",
    address: "",
    province: "",
    poblation: "",
    cp: "",
    email: "",
    estamento: "",
    sport: "",
    dni_image: "",
    firma: "",
  });
  const [filteredPoblacion, setFilteredPoblacion] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const handleClear = (e) => {
    e.preventDefault();
    sigPad.current.clear();
  };

  const handleFileDNIChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    //Check the file size
    const maxSizeInMB = 2;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
    if (file?.size > maxSizeInBytes) {
      setFormErrors((prev) => ({
        ...prev,
        dni_image: `El tamaño del archivo no debe superar los ${maxSizeInMB}MB`,
      }));
      setFormData((prevData) => ({ ...prevData, dni_image: "" }));
      return;
    } else {
      validateField("dni_image", e);
    }
    const reader = new FileReader();

    reader.onloadend = () => {
      setFormData((prevData) => ({ ...prevData, dni_image: reader.result }));
    };

    reader.readAsDataURL(file);
  };

  const handleFileRepresentanteChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    //Check the file size
    const maxSizeInMB = 2;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
    if (file?.size > maxSizeInBytes) {
      setFormErrors((prev) => ({
        ...prev,
        representante: `El tamaño del archivo no debe superar los ${maxSizeInMB}MB`,
      }));
      setFormData((prevData) => ({ ...prevData, representante: "" }));
      return;
    }
    const reader = new FileReader();

    reader.onloadend = () => {
      setFormData((prevData) => ({ ...prevData, representante: reader.result }));
    };

    reader.readAsDataURL(file);
  };

  const validateField = (name, value) => {
    switch (name) {
      case "email":
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
          setFormErrors((prev) => ({ ...prev, email: "Correo electrónico no válido" }));
        } else {
          setFormErrors((prev) => ({ ...prev, email: "" }));
        }
        break;
      case "cp":
        if (!/^\d{5}$/.test(value)) {
          // Asume código postal de 5 dígitos como ejemplo
          setFormErrors((prev) => ({ ...prev, cp: "Código postal debe ser de 5 dígitos" }));
        } else {
          setFormErrors((prev) => ({ ...prev, cp: "" }));
        }
        break;
      case "name":
        if (value.length < 3) {
          setFormErrors((prev) => ({
            ...prev,
            name: "El nombre debe tener al menos 3 caracteres.",
          }));
        } else {
          setFormErrors((prev) => ({ ...prev, name: "" }));
        }
        break;
      case "surname1":
        if (value.length < 2) {
          setFormErrors((prev) => ({
            ...prev,
            surname1: "Los apellidos deben tener al menos 2 caracteres.",
          }));
        } else {
          setFormErrors((prev) => ({ ...prev, surname1: "" }));
        }
        break;
      case "nif":
        if (!(/^\d{8}[A-Z]$/i.test(value) || /^[A-Z0-9]{5,20}$/i.test(value))) {
          setFormErrors((prev) => ({ ...prev, nif: "Formato de nif o pasaporte no válido." }));
        } else {
          setFormErrors((prev) => ({ ...prev, nif: "" }));
        }
        break;
      case "cnr":
        if (formData.estamento === "Clubes" || formData.estamento === "Clubes Alto Nivel") {
          if (!/^[A-Za-z0-9]{6,}$/.test(value)) {
            setFormErrors((prev) => ({
              ...prev,
              cnr: "Formato de cnr no válido. Debe tener al menos 6 caracteres alfanuméricos.",
            }));
          } else {
            setFormErrors((prev) => ({ ...prev, cnr: "" }));
          }
        } else {
          if (!/^\d{10}$/.test(value)) {
            setFormErrors((prev) => ({
              ...prev,
              cnr: "Formato de cnr no válido. Son 10 dígitos numéricos",
            }));
          } else {
            setFormErrors((prev) => ({ ...prev, cnr: "" }));
          }
        }

        break;
      case "address":
        if (value.length > 100 || value.length < 3) {
          // Simple pattern for Spanish nif or Passport
          setFormErrors((prev) => ({ ...prev, address: "Máximo 100 carácteres" }));
        } else {
          setFormErrors((prev) => ({ ...prev, address: "" }));
        }
        break;
      case "sport":
        if (value.length < 3) {
          // Simple pattern for Spanish nif or Passport
          setFormErrors((prev) => ({ ...prev, sport: "Selecciona un deporte" }));
        } else {
          setFormErrors((prev) => ({ ...prev, sport: "" }));
        }
        break;
      case "estamento":
        if (value.length < 3) {
          // Simple pattern for Spanish nif or Passport
          setFormErrors((prev) => ({ ...prev, estamento: "Selecciona un estamento" }));
        } else {
          setFormErrors((prev) => ({ ...prev, estamento: "" }));
        }
        break;
      case "province":
        if (value.length < 3) {
          // Simple pattern for Spanish nif or Passport
          setFormErrors((prev) => ({ ...prev, province: "Selecciona un province" }));
        } else {
          setFormErrors((prev) => ({ ...prev, province: "" }));
        }
        break;
      case "poblation":
        if (value.length < 3) {
          // Simple pattern for Spanish nif or Passport
          setFormErrors((prev) => ({ ...prev, poblation: "Selecciona una población" }));
        } else {
          setFormErrors((prev) => ({ ...prev, poblation: "" }));
        }
        break;
      case "firma":
        if (value.length < 150) {
          // Simple pattern for Spanish nif or Passport
          setFormErrors((prev) => ({ ...prev, firma: "Tienes que firmar" }));
        } else {
          setFormErrors((prev) => ({ ...prev, firma: "" }));
        }
        break;
      case "dni_image":
        if (value.length < 2) {
          // Simple pattern for Spanish nif or Passport
          setFormErrors((prev) => ({
            ...prev,
            dni_image: "Tienes que añadir tu anverso del documento de identidad o pasaporte",
          }));
        } else {
          setFormErrors((prev) => ({ ...prev, dni_image: "" }));
        }
        break;
      // case 'representante':
      //   if (formData.estamento === 'Clubes') {
      //     if (value.length < 2) { // Simple pattern for Spanish nif or Passport
      //       setFormErrors(prev => ({ ...prev, representante: 'Designación de representante de club filtrado por club obligatorio' }));
      //     } else {
      //       setFormErrors(prev => ({ ...prev, representante: '' }));
      //     }
      //   }
      //   break;
      default:
        break;
    }
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  //   validateField(name, value);
  // };

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;
    setFormData({ ...formData, [name]: fieldValue });
    validateField(name, fieldValue);
  };

  const handleChangeProvince = (event) => {
    const selectedProvinceLabel = event.target.value;
    const selectedProvinceCode = provincesData.find(
      (province) => province.label === selectedProvinceLabel
    )?.code;
    const filteredPoblations = poblationData.filter(
      (poblation) => poblation.parent_code === selectedProvinceCode
    );

    setFormData((prev) => ({ ...prev, province: selectedProvinceLabel, poblation: "" }));
    setFilteredPoblacion(filteredPoblations);
  };

  const handleChangePoblation = (event) => {
    setFormData((prev) => ({ ...prev, poblation: event.target.value }));
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateField(name, value);
  };

  const isFormValid = () => {
    // Verifica que todos los campos requeridos estén llenos
    let allFieldsFilled;

    if (
      formData.address !== "" &&
      formData.name !== "" &&
      formData.surname1 !== "" &&
      formData.nif !== "" &&
      formData.cnr !== "" &&
      formData.poblation !== "" &&
      formData.province !== "" &&
      formData.cp !== "" &&
      formData.estamento !== "" &&
      formData.email !== "" &&
      formData.sport !== "" &&
      formData.dni_image !== "" &&
      formData.firma !== ""
    ) {
      if (formData.estamento === "Clubes" && formData.representante === "") {
        allFieldsFilled = false;
      }
      allFieldsFilled = true;
    } else {
      allFieldsFilled = false;
    }
    // Verifica que no haya errores
    const noErrors = Object.values(formErrors).every((error) => error === "");
    return allFieldsFilled && noErrors;
  };

  const handleSave = (e) => {
    e.preventDefault();
    const signatureImage = sigPad.current.getTrimmedCanvas().toDataURL("image/png");
    setFormData((prevData) => ({ ...prevData, firma: signatureImage }));
  };

  const resetForm = () => {
    handleClear();
    setFormData(initialFormData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingSubmit(true);
    //estio es para convertir a bas64 la firma
    const signatureImage = sigPad.current.getTrimmedCanvas().toDataURL("image/png");
    setFormData((prevData) => ({ ...prevData, firma: signatureImage }));

    if (formData.firma.length > 150) {
      // CHECKEO SI EL FORMULARIO ES VALIDO
      if (!isFormValid()) {
        setLoadingSubmit(false);
        return;
      }

      try {
        fetch(`${SERVICE.USERS}/create-user-censo`, {
          method: "POST",
          headers: { Accept: "application/json" },
          body: JSON.stringify(formData),
        })
          .then((response) => {
            if (response.status === 400) {
              console.error("Error 400:", response);
              setLoadingSubmit(false);
              return response.json();
            }
            if (!response.ok) {
              console.error("Error:", response);
              setLoadingSubmit(false);
            }
            return response.json();
          })
          .then((data) => {
            if (data.error) {
              console.error("Error:", data.error);
              toastMessageError(data.error ? data.error : "Error algo salío mal");
              setLoadingSubmit(false);
              return "";
            }
            // Manejar la respuesta del servidor aquí
            recogerConsentimiento(
              document.getElementById("rfejyda-censo"),
              "rfejyda-censo",
              "votocorreo.rfejudo.com"
            );
            toastMessageSuccess("Registro realizado con éxito, has tenido que recibir un email");
            // Después de procesar los datos, restablece el formulario
            resetForm();
            setLoadingSubmit(false);
          })
          .catch((error) => {
            console.error("Error:", error);
            toastMessageError(error ? error : "Error algo salió mal ");
            setLoadingSubmit(false);
          });
      } catch (error) {
        console.error("Error al enviar los datos:", error);
        setLoadingSubmit(false);
      }
    } else {
      toastMessageError("La firma es obligatoria");
      setLoadingSubmit(false);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          style={{ zIndex: "100001" }}
        />
        <div className="login-container">
          <img className="login-logo" src="04.png" alt="Logo judo España" />
        </div>
        <Typography className="login-title" component="h1" variant="h5">
          Elecciones 2024
        </Typography>
        <Typography className="login-subtitle" component="h2" variant="h5">
          Real Federación Española de Judo y Deportes Asociados
        </Typography>
        <Typography className="login-ttitle" component="h3" variant="h5">
          Formulario, solicitud inclusión en censo especial de voto no presencial
        </Typography>

        <Box
          component="form"
          id="rfejyda-censo"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
          sx={{ display: "flex", flexDirection: "column", gap: 2, m: 2, maxWidth: "90vw" }}
        >
          <TextField
            label="Nombre"
            name="name"
            value={formData.name}
            onChange={handleChange}
            variant="outlined"
            onBlur={handleBlur}
            error={!!formErrors.name}
            helperText={formErrors.name}
            required
          />

          <TextField
            label="Apellido 1"
            name="surname1"
            value={formData.surname1}
            onChange={handleChange}
            variant="outlined"
            onBlur={handleBlur}
            error={!!formErrors.surname1}
            helperText={formErrors.surname1}
            required
          />
          <TextField
            label="Apellido 2"
            name="surname2"
            value={formData.surname2}
            onChange={handleChange}
            variant="outlined"
            onBlur={handleBlur}
          />
          <TextField
            label="Correo Electrónico"
            name="email"
            value={formData.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!formErrors.email}
            helperText={formErrors.email}
            variant="outlined"
            required
          />
          <TextField
            label="NIF"
            name="nif"
            value={formData.nif}
            onChange={handleChange}
            variant="outlined"
            onBlur={handleBlur}
            error={!!formErrors.nif}
            helperText={formErrors.nif}
            required
          />
          <TextField
            type="file"
            name="dni_image"
            label="DNI o Pasaporte"
            onChange={handleFileDNIChange}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            required
            error={!!formErrors.dni_image}
            helperText={formErrors.dni_image}
          />

          {/* PROVINCIA */}
          <FormControl fullWidth>
            <InputLabel id="province-label">Provincia</InputLabel>
            <Select
              labelId="province-label"
              name="province"
              value={formData.province}
              label="Provincia"
              onChange={handleChangeProvince}
              required
              onBlur={handleBlur}
              error={!!formErrors.province}
              helperText={formErrors.province}
            >
              <MenuItem value="">
                <em>Seleccione una province</em>
              </MenuItem>
              {provincesData.map((province, index) => (
                <MenuItem key={`${province.code}-${index}`} value={province.label}>
                  {province.label}
                </MenuItem>
              ))}
            </Select>
            {formErrors.province && <div className="errorText">{formErrors.province}</div>}
          </FormControl>

          {/* POBLACION */}
          <FormControl fullWidth>
            <InputLabel id="province-label">Población</InputLabel>
            <Select
              labelId="province-label"
              name="poblation"
              value={formData.poblation}
              label="Población"
              onChange={handleChangePoblation}
              required
              onBlur={handleBlur}
              error={!!formErrors.poblation}
              helperText={formErrors.poblation}
            >
              <MenuItem value="">
                <em>Seleccione una población</em>
              </MenuItem>
              {filteredPoblacion.map((poblation, index) => (
                <MenuItem key={`${poblation.code}-${index}`} value={poblation.label}>
                  {poblation.label}
                </MenuItem>
              ))}
            </Select>
            {formErrors.poblation && <div className="errorText">{formErrors.poblation}</div>}
          </FormControl>
          {/* DIRECCION */}
          <TextField
            label="Dirección para el envío de la documentación electoral"
            name="address"
            value={formData.address}
            onChange={handleChange}
            variant="outlined"
            onBlur={handleBlur}
            error={!!formErrors.address}
            helperText={formErrors.address}
            required
          />
          {/* CODIGO POSTAL */}
          <TextField
            label="Código Postal"
            name="cp"
            value={formData.cp}
            onChange={handleChange}
            variant="outlined"
            onBlur={handleBlur}
            error={!!formErrors.cp}
            helperText={formErrors.cp}
            required
          />

          <FormControl>
            <InputLabel id="estamento-label">Estamento</InputLabel>
            <Select
              labelId="estamento-label"
              name="estamento"
              value={formData.estamento}
              label="Estamento"
              onChange={handleChange}
              required
              onBlur={handleBlur}
              error={!!formErrors.estamento}
              helperText={formErrors.estamento}
            >
              <MenuItem value="">Seleccione Estamento</MenuItem>
              <MenuItem value="Clubes">Clubes</MenuItem>
              <MenuItem value="Clubes Alto Nivel">Clubes de máxima categoría</MenuItem>
              <MenuItem value="Deportistas">Deportistas</MenuItem>
              <MenuItem value="Deportistas de Alto Nivel">Deportistas de Alto Nivel</MenuItem>
              <MenuItem value="Técnicos">Técnicos</MenuItem>
              <MenuItem value="Técnicos de Alto Nivel">Técnicos de Alto Nivel</MenuItem>
              <MenuItem value="Jueces árbitros">Jueces árbitros</MenuItem>
            </Select>
            {formErrors.estamento && <div className="errorText">{formErrors.estamento}</div>}
          </FormControl>
          {(formData.estamento === "Clubes" || formData.estamento === "Clubes Alto Nivel") && (
            <>
              <TextField
                type="file"
                name="representante"
                onChange={handleFileRepresentanteChange}
                label="Designación de representante de club"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                onBlur={handleBlur}
                error={!!formErrors.representante}
                helperText={formErrors.representante}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.certified_voto_club}
                    onChange={handleChange}
                    name="certified_voto_club"
                    color="primary"
                    required
                  />
                }
                label={
                  <div>
                    <p>CERTIFICA</p>
                    <p>
                      Que la Junta Directiva / Asamblea General del Club acordó SOLICITAR la
                      inclusión en el censo electoral de voto no presencial de la RFEJYDA, a los
                      efectos de poder ejercer el derecho de voto por correo en el proceso electoral
                    </p>
                  </div>
                }
              />
            </>
          )}

          <TextField
            label={
              formData.estamento === "Clubes" || formData.estamento === "Clubes Alto Nivel"
                ? "Código del club"
                : "CNR"
            }
            name="cnr"
            value={formData.cnr}
            onChange={handleChange}
            variant="outlined"
            onBlur={handleBlur}
            error={!!formErrors.cnr}
            helperText={formErrors.cnr}
            required
          />
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              Si has seleccionado el estamento de Clubes o Clubes Alto Nivel, debes introducir el
              CNR del club, tambien llamado código de club. <br />
              Puedes obtener tu CNR en el siguiente enlace de censo provisional, recuerda introducir
              tus datos correctamente, el CNR o código de club aparecerá en la columna Licencia/
              C.N.R.
              <a
                href="https://www.rfejudo.com/documentos/censo/"
                target="_blank"
                rel="noreferrer noopener"
              >
                {" "}
                Pincha aquí
              </a>
            </div>
            <Tooltip
              title={<img src="club.png" alt="CNR explanation" style={{ width: 200 }} />}
              placement="right"
              arrow
            >
              <IconButton>
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
          </div>
          <FormControl>
            <InputLabel id="especialidad-deportiva-label">Especialidad Deportiva</InputLabel>
            <Select
              labelId="especialidad-deportiva-label"
              name="sport"
              value={formData.sport}
              label="Especialidad Deportiva"
              onChange={handleChange}
              required
              onBlur={handleBlur}
              error={!!formErrors.sport}
              helperText={formErrors.sport}
            >
              <MenuItem value="">Seleccione Especialidad Deportiva</MenuItem>
              <MenuItem value="Judo">Judo</MenuItem>
              <MenuItem value="Jiu-Jitsu">Jiu-Jitsu</MenuItem>
              <MenuItem value="Aikido">Aikido</MenuItem>
              <MenuItem value="Kendo">Kendo</MenuItem>
              <MenuItem value="Wushu">Wushu</MenuItem>
              <MenuItem value="Defensa Personal">Defensa Personal</MenuItem>
            </Select>
          </FormControl>
          {formErrors.sport && <div className="errorText">{formErrors.sport}</div>}

          {/* FIRMA */}
          <div className="firma">
            <SignatureCanvas
              penColor="black"
              canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
              ref={sigPad}
              onBlur={handleBlur}
              error={!!formErrors.firma}
              helperText={formErrors.firma}
            />

            <button onClick={handleClear}>Limpiar/Borrar</button>
            {formErrors.firma && <div className="errorText">{formErrors.firma}</div>}
            <button onClick={handleSave}>Guardar firma</button>
          </div>
          <div>Este formulario ya no está disponible, desde las 15:00h del 7 de junio</div>
          {/* <Button
            type="submit"
            variant="contained"
            disabled={!isFormValid()}
          >
            Enviar
          </Button> */}
        </Box>
        <div style={{ margin: "50px", fontSize: "small" }}>
          <p>
            Si tiene algún problema póngase en contacto con{" "}
            <a href="mailto:soportevotoporcorreo@rfejudo.com">soportevotoporcorreo@rfejudo.com</a>
          </p>
        </div>
        <div style={{ margin: "50px", fontSize: "small" }}>
          <p>
            El tratamiento y publicación de los datos contenidos en el censo electoral, tendrá por
            exclusiva finalidad garantizar el ejercicio por los electores de su derecho de sufragio
            y garantizar la transparencia del proceso electoral, no siendo posible su utilización ni
            cesión para ninguna otra finalidad distinta de aquella, quedando prohibida cualquier
            información particularizada sobre los datos contenidos en el censo electoral, una vez
            transcurrido el proceso electoral los citados datos serán eliminados . Tampoco está
            permitido la descarga de archivos con la información del censo (arte 6.4 párrafo 2 de la
            Orden de procesos electorales). En todo caso será de aplicación lo previsto en la Ley
            Orgánica 3/2018, de 5 de diciembre de protección de datos personales y garantía de los
            derechos digitales
          </p>
        </div>
      </div>
    </>
  );
}

export default App;
