
export const BASE_API_URL =	'https://63571hrcdf.execute-api.eu-west-1.amazonaws.com';


const APP_URL = '/prod/v1';
// const APP_URL = '/test/v1';

export const API_URL = `${BASE_API_URL}`;

export const SERVICE = {
	BASE: APP_URL,
	USERS: `${API_URL}${APP_URL}/users`,
}