/* eslint-disable no-restricted-globals */
import { useEffect } from "react";

function includejQuery() {
  if (window.jQuery) {
    console.log('jQuery already loaded');
  } else {
    const jq = document.createElement("script");
    jq.src = "https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js";
    jq.onload = () => console.log("jQuery loaded successfully");
    document.body.appendChild(jq);
  }
}

export default function useConcentScript() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://consentimientos.com/webservice/gfm_consentimientos_b.js";
    script.async = true;
    script.onload = () => {
      includejQuery();
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const recogerConsentimiento = (form) =>
    // @ts-ignore
    window.recogerConsentimiento(
      form,
      location.host,
      location.protocol + "//" + location.host + location.pathname
    );

  return { recogerConsentimiento };
}
